import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, chunk } from 'lodash';
import withRouter from 'react-router/withRouter';

import loadable from '@loadable/component';

const NotFound = loadable(() =>
  import('../../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);
const BtmLayout = loadable(
  () => import('../../../../fc-components/main/containers'),
  {
    resolveComponent: components => components.BtmLayout
  }
);
const Button = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Button
  }
);
const IconTitle = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.IconTitle
  }
);
const Image = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Image
  }
);
const Section = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const Text = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Text
  }
);
const BtmPastHonereesLogos = loadable(
  () => import('../../../../components/BtmPastHonereesLogos/index'),
  {
    resolveComponent: components => components.BtmPastHonereesLogos
  }
);
const BtmCmos = loadable(() => import('../../../../components/BtmCmos/index'), {
  resolveComponent: components => components.BtmCmos
});
const BtmImportantDates = loadable(
  () => import('../../../../components/BtmImportantDates/index'),
  {
    resolveComponent: components => components.BtmImportantDates
  }
);

const BtmApplyPage = props => {
  const fullPackageObj = get(props, 'franchisePage', null);
  const packageData = get(fullPackageObj, 'data', {});

  const packageBlurb = get(packageData, 'acf.franchise_blurb', '');
  const eligibilityBlurb = get(
    packageData,
    'acf.application_page.eligibility',
    ''
  );
  const categoryBlurb = get(
    packageData,
    'acf.application_page.categories[0].categoryGroup.description',
    ''
  );

  const acceptingApplications = get(
    packageData,
    'acf.application_page.acceptingApplications',
    undefined
  );
  const packageDeadline = get(
    packageData,
    'acf.application_page.deadline',
    undefined
  );
  const packageApplyUrl = get(
    packageData,
    'acf.application_page.applyUrl',
    '#'
  );
  const packageButtonText = get(
    packageData,
    'acf.application_page.buttonText',
    '#'
  );
  const packageCategories = get(
    packageData,
    'acf.application_page.categories',
    []
  );
  const packageWhyApply = get(packageData, 'acf.application_page.whyApply', []);
  const whyApplyColumns = chunk(
    packageWhyApply,
    (packageWhyApply.length + 1) / 2
  );
  const franchiseFeatureImageDesktop = get(
    packageData,
    'acf.franchise_images.franchise_animated_svg_desktop'
  );
  const importantDates =
    packageData?.acf?.application_page?.important_dates || [];
  const howToApply = packageData?.acf?.application_page?.how_to_apply || [];
  const howToApplyChunks = chunk(howToApply, (howToApply.length + 1) / 2);
  const cmos = packageData?.acf?.application_page?.cmos || {};

  // Initiate from Redux
  const { franchisePage, status } = props;

  // Handle Lazy Loading & Errors
  if (franchisePage.error || status === 404 || isEmpty(fullPackageObj)) {
    return (
      <section className="brands-that-matter-page">
        <article className="brands-that-matter__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  if (franchisePage.isLoading) {
    return (
      <section className="brands-that-matter-page">
        <article className="brands-that-matter__main">
          <LoadingSpinner />
        </article>
      </section>
    );
  }

  return (
    <BtmLayout>
      <Section name="topHero" maxWidth={688} bgColor="#B983FF">
        <div className="topItems">
          <Image image={franchiseFeatureImageDesktop} />
          {acceptingApplications && (
            <a href={packageApplyUrl}>
              <Button
                bgColor="#004FDF"
                labelHoverColor="#000000"
                labelColor="#FFFFFF"
                hoverColor="#D7D7D7"
                rounded
                label={packageButtonText}
              />
            </a>
          )}
          <Text as="h2" color="#000000">
            {packageDeadline}
          </Text>
        </div>
      </Section>

      <Section name="blurb" maxWidth={860} vMargins="20">
        <div className="sectionHeading">
          <IconTitle title="BRANDS THAT MATTER AWARDS" underline />
        </div>
        <Text as="div">
          <div dangerouslySetInnerHTML={{ __html: packageBlurb }} />
        </Text>
        {acceptingApplications && (
          <a href={packageApplyUrl}>
            <Button
              bgColor="#004FDF"
              labelHoverColor="#000000"
              labelColor="#FFFFFF"
              hoverColor="#D7D7D7"
              rounded
              label={packageButtonText}
            />
          </a>
        )}
        <div className="deadlineSubText">
          <Text as="h6" color="#000000">
            {packageDeadline}
          </Text>
        </div>
      </Section>

      <Section name="whyApply" maxWidth={860} bgColor="#000000">
        <div className="sectionHeading">
          <IconTitle
            title="WHY APPLY"
            color="#ffffff"
            underline
            underlineColor="#ffffff"
          />
        </div>
        <div className="reasonContainer">
          {whyApplyColumns.map((column, i) => (
            <div key={`whyApply-column-${i}`} className="whyApply-column">
              {column.map((reason, index) => (
                <div key={`whyApply-${index}`} className="whyApplyItem">
                  <div className="icon">
                    <Image image={get(reason, 'icon.url')} />
                  </div>
                  <div>
                    <Text as="h3" color="#ffffff">
                      {reason.title}
                    </Text>
                    <Text
                      fontFamily="'CentraNo2Book', sans-serif"
                      fontSize="15px"
                      lineHeight="17px"
                      as="div"
                      color="#cccccc"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: reason.description }}
                      />
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Section>

      <Section name="eligibility" maxWidth={860}>
        <div className="sectionHeading">
          <IconTitle title="ELIGIBILITY" underline />
        </div>
        <Text as="div">
          <div dangerouslySetInnerHTML={{ __html: eligibilityBlurb }} />
        </Text>
      </Section>

      <Section name="categories" maxWidth={860}>
        <div className="sectionHeading">
          <IconTitle title="CATEGORIES" underline />
        </div>
        <Text as="div">
          <div dangerouslySetInnerHTML={{ __html: categoryBlurb }} />
        </Text>
        {acceptingApplications && (
          <a href={packageApplyUrl}>
            <Button
              bgColor="#004FDF"
              labelHoverColor="#000000"
              labelColor="#FFFFFF"
              hoverColor="#D7D7D7"
              rounded
              label={packageButtonText}
            />
          </a>
        )}
        <div className="deadlineSubText">
          <Text as="h6" color="#000000">
            {packageDeadline}
          </Text>
        </div>
        <div>
          {packageCategories.map((category, index) => {
            const categoryGroup = get(category, 'categoryGroup', {});
            const categoryName = get(categoryGroup, 'name', ''); // eslint-disable-line
            const categoryItems = get(categoryGroup, 'items', []);

            return (
              <div
                key={`category-container-${index}`}
                className="categoryContainer"
              >
                <div className="columnOne">
                  {categoryItems.map((item, i) => {
                    if (i % 2 === 0) {
                      return (
                        <div key={`category-item-${i}`} className="categoyCell">
                          <Text as="h3">
                            {item.title}{' '}
                            {item.subHed ? <span>{item.subHed}</span> : ''}
                          </Text>
                          <Text
                            fontFamily="'CentraNo2Book', sans-serif"
                            fontSize="15px"
                            lineHeight="17px"
                            as="div"
                            color="#5f5f5f"
                          >
                            {item.description}
                          </Text>
                        </div>
                      );
                    }
                    return '';
                  })}
                </div>
                <div className="columnTwo">
                  {categoryItems.map((item, i) => {
                    if (i % 2 === 1) {
                      return (
                        <div key={`category-item-${i}`} className="categoyCell">
                          <Text as="h3">
                            {item.title}{' '}
                            {item.subHed ? <span>{item.subHed}</span> : ''}
                          </Text>
                          <Text
                            fontFamily="'CentraNo2Book', sans-serif"
                            fontSize="15px"
                            lineHeight="17px"
                            as="div"
                            color="#5f5f5f"
                          >
                            {item.description}
                          </Text>
                        </div>
                      );
                    }
                    return '';
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Section>

      <Section name="finalApply" maxWidth={860}>
        {acceptingApplications && (
          <a href={packageApplyUrl}>
            <Button
              bgColor="#004FDF"
              labelHoverColor="#000000"
              labelColor="#FFFFFF"
              hoverColor="#D7D7D7"
              rounded
              label={packageButtonText}
            />
          </a>
        )}
        <div className="deadlineSubText">
          <Text as="h6" color="#000000">
            {packageDeadline}
          </Text>
        </div>
      </Section>

      <Section name="howToApply" maxWidth={860}>
        <div className="sectionHeading">
          <IconTitle title="HOW TO APPLY" underline />
        </div>
        <div className="reasonContainer">
          {howToApplyChunks.map((column, i) => (
            <div key={`howToApply-column-${i}`} className="howToApply-column">
              {column.map((reason, index) => (
                <div key={`howToApply-${index}`} className="whyApplyItem">
                  <div className="icon">
                    <Image image={reason?.icon} />
                  </div>
                  <div>
                    <Text as="h3">{reason.title}</Text>
                    <Text
                      fontFamily="'CentraNo2Book', sans-serif"
                      fontSize="15px"
                      lineHeight="17px"
                      as="div"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: reason.description }}
                      />
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Section>

      <Section name="bottomFooter" maxWidth={860} bgColor="#000000">
        <IconTitle title="PAST HONOREES INCLUDE" underline />
        <Text as="div">
          See all of last year's honorees{' '}
          <a
            href="https://www.fastcompany.com/brands-that-matter/list"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            here.{' '}
          </a>
        </Text>
        <BtmPastHonereesLogos />
      </Section>

      <Section name="cmos" maxWidth={860}>
        <IconTitle title="CMOS" underline />
        <BtmCmos cmos={cmos.list} descrtiption={cmos.description} />
      </Section>

      {importantDates?.length > 0 && (
        <Section name="importantDates" maxWidth={860} bgColor="#000000">
          <IconTitle
            title="IMPORTANT DATES"
            color="#FF006D"
            underlineColor="#FF006D"
            underline
          />
          <BtmImportantDates
            dates={importantDates}
            packageButtonText={packageButtonText}
            packageApplyUrl={packageApplyUrl}
            acceptingApplication={acceptingApplications}
          />
          <div>
            {acceptingApplications && (
              <a href={packageApplyUrl}>
                <Button
                  bgColor="#004FDF"
                  labelHoverColor="#000000"
                  labelColor="#FFFFFF"
                  hoverColor="#D7D7D7"
                  rounded
                  label={packageButtonText}
                />
              </a>
            )}
            <div className="deadlineSubText">
              <Text as="h6" color="#FFFFFF">
                {packageDeadline}
              </Text>
            </div>
          </div>
        </Section>
      )}
    </BtmLayout>
  );
};

BtmApplyPage.propTypes = {
  status: PropTypes.number.isRequired,
  franchisePage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired
};

export default withRouter(BtmApplyPage);
